<template>
  <div class="row">
    <b-modal ref="keySuccess" size="lg" ok-only no-enforce-focus hide-header>
      <div style="text-align: center">
        <h1>New API Key created</h1>
        <b-alert show variant="warning">
          For security reasons we show each generated key once.
        </b-alert>
        <b-form-textarea
          v-model="key"
          rows="3"
          max-rows="6"
          disabled
        />
      </div>
    </b-modal>

    <b-modal ref="modal" size="sm" no-enforce-focus hide-header>
      <form @submit.prevent="save">
        <b-form-group label="Name" label-for="name">
          <b-form-input id="name" v-model="item.name" :state="state('name')" type="text" placeholder="Name" />
        </b-form-group>
        <input type="submit" style="visibility: hidden;">
      </form>
      <template v-slot:modal-footer="{ cancel }">
        <b-button variant="success" @click="save()">
          Save
        </b-button>
        <b-button variant="dark" @click="cancel()">
          Cancel
        </b-button>
      </template>
    </b-modal>
    <div class="col">
      <b-card title="My API Keys">
        <blueprint-data ref="data" no-edit api-route-path="users/me/apikeys" :fields="fields">
          <template v-slot:new="{ }">
            <b-button variant="success" @click="newItem">
              <fa-icon icon="plus" /> New
            </b-button>
          </template>
        </blueprint-data>
      </b-card>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      errors: {},
      item: {},
      key: '',
      fields: [
        {
          key: 'name',
          sortable: true,
        },
        {
          key: 'userFriendlyKeyInfo',
          label: 'Key',
          sortable: true,
        },
      ],
    };
  },
  methods: {
    state (item) {
      return (this.errors[item] ? false : null);
    },
    newItem () {
      this.errors = {};
      this.$refs.modal.show();
    },
    save () {
      const promise = this.$http.post(`users/me/apikeys`, this.item);
      promise
        .then((res) => {
          this.$refs.modal.hide();
          this.key = res.data.key;
          this.$refs.keySuccess.show();
          this.item = {};
        })
        .then(() => this.$refs.data.getData())
        .catch(this.err);
    },
  },
};
</script>
